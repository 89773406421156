/* Provide sufficient contrast against white background */
html, body {
  font-size: 14px;
  height: 100%;
}

.fileList {
  display: inline-block;
  margin-left: 0.5rem;
  max-width: 85%;
  vertical-align: top;
}

.hidden {
  display: none;
}

.inputButton {
  padding: 0.15rem 0.55rem;
  font-size: 1rem;
  border-radius: 2px;
  border: 1px solid #707070;
  background: #efefef;
  cursor: pointer;
}

div#root,
div#root>div {
  height: 100%;
}

a {
  color: #0366d6;
}

a.masterLink {
  text-decoration: underline;
}

code {
  color: #E01A76;
}

div.modal-content {
  color: #3f929b;
}

div.modal-content h3 {
  color: #46748a;
  font-weight: 600;
  font-size: 1.8rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  margin-bottom: 0;
  width: 100%;
  border-bottom: 1px solid #dee2e6;
}

div.modal-content .btn.btn-secondary {
  background-color: #c1c1c1;
  border-color: #c1c1c1;
}

div.modal-content .btn.btn-danger {
  background-color: #ce5656;
  border-color: #ce5656;
}

div.modal-content button.btn {
  min-width: 7rem;
  font-weight: 400;
}

div.master .table thead th {
  border-top: none;
  border-bottom: none;
  background-color: #F5F5FF;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

div.help {
  position: absolute;
  right: 0px;
  top: 0;
  width: 25%;
  height: 100%;
  border-left: 5px solid #D0D0D0;
  padding-left: 2rem;
  padding-top: 2rem;
  overflow-y: auto;
}

div.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

table.masterTable thead th {
  background-color: #F5F5FF;
  border-top: none;
  border-bottom: none;
}

table.masterTable thead th:first-child,
table.masterTable tbody td:first-child {
  padding-left: 1rem;
}

table.masterTable thead th:last-child,
table.masterTable tbody td:last-child {
  padding-right: 1rem;
}

table.templateTable {
  width: 98%;
}
table.templateTable td,
table.templateTable th {
  text-align: center;
}

div.master {
  height: 94%;
  width: 100%;
  position: relative;
  padding-left: 1rem;
  padding-top: 1rem;
}

div.master h3 {
  color: #5c8cce;
  font-size: 1.55rem;
  font-weight: 500;
}

div.master table {
  width: auto;
}

div.master table th,
div.master table td {
  padding-right: 2rem;
}

div.master table th,
div.master table td {
  padding-left: 0.5rem;
  border-left: none;
  border-right: none;
}

button.masterButton {
  width: 10rem;
  height: 3rem;
  margin-right: 1rem;
  background-color: #5c8cce;
}

.buttonRow {
  margin-bottom: 1rem;
}

.buttonRow.row,
.buttonRow.row + .row {
  margin-right: 0;
  margin-left: 0;
}

div.master svg.fa-check {
  color: #666;
}

div.master .table-responsive>.table-bordered {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

div.master .table-bordered thead th,
div.master .table-bordered thead td {
  border-bottom: 1px;
}

div.master table th {
  font-weight: 400;
  font-size: 1.1rem;
}

div.master table th.centerCell,
div.master table td.centerCell {
  vertical-align: middle;
  text-align: center;
}

div.master table input[type=text] {
  width: 100%;
}

header {
  height: 6%;
}

.navbar {
  height: 100%;
}

.navLogo {
  height: 2.5rem;
  margin-right: 1rem;
}

input.removeRow {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  line-height: 0.7rem;
  font-size: 0.8rem;
  border-radius: 0.2rem;
  margin: auto;
  border: none;
  background: #5c8cce;
  color: #FFFFFF;
}

input.removeRow {
  background: #ce5656;
}